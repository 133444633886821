@keyframes FadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.fade-in {
    animation: FadeIn 300ms forwards;
}

.str-chat {
    height: 800px;
}

.link-item {
    cursor: pointer;
    text-decoration: underline;
    font-weight: bolder;
}
.link-item:hover {
    color: #0f78d9;
    transition: 0.3s;
}
.grecaptcha-badge {
	visibility: hidden;
}
